import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SchoolInfoStore from "../../../../mobx/SchoolInfoStore";
import { toJS } from "mobx";
import CustomChip from "../../../CustomChip/CustomChip";
import {getLessonsStartPreferencesHours, getLessonStartDataName, pluralize, pluralizeCommonWords} from "./requirementFeatures";


const findClassesForGroups = (groups, flag) => {
  const { classes } = toJS(SchoolInfoStore);
  const groupsArray = [];

  if (flag === "GROUP") {
    classes?.data?.forEach((cl) => {
      cl?.groups?.forEach((gr) => {
        groups?.forEach((g) => {
          if (g._id.$oid === gr._id.$oid) {
            groupsArray.push({ name: `${cl.name} - ${gr.name}` });
          }
        });
      });
    });
    return groupsArray;
  }

  return groups;
};


const possibleRequirements = {
  PREFERENCE: (requirement) => {
    const spc = toJS(SchoolInfoStore.schoolPlanConfig.data[0]);
    const { slotsNames, daysNames } = spc;
    const { slots } = requirement;

    const getHoursAndDays = getLessonsStartPreferencesHours({slotsNames, daysNames, slots});
    console.log(getHoursAndDays)    
    
    return (
      <Accordion style={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${requirement._id.$oid}-content`}
          id={`${requirement._id.$oid}-header`}
        >
          <Typography variant="h5">
            {translateRequirementType(requirement.type).toUpperCase()}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
            with={"100%"}
            gap={1}
          >
            <Stack width={"20%"} minWidth={'150px'}>
              <Typography variant="body1" fontWeight={"bold"}>
                Typ preferencji
              </Typography>
              <Typography variant="subtitle2">
                {translateRequirementType(requirement.objType)}
              </Typography>
            </Stack>
            <Divider />
            <Stack width={"80%"}>
              <Typography variant="body1" fontWeight={"bold"}>
                {pluralizeCommonWords(translateRequirementType(requirement.objType), requirement?.idsDetails?.length)}
              </Typography>
              <Stack
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-start"}
                gap={1}
                flexWrap={"wrap"}
              >
                {requirement?.idsDetails?.length > 0 ? (
                  findClassesForGroups(requirement.idsDetails, requirement.objType).map((detail) => (
                    <CustomChip label={detail.name}/>
                  ))
                ) : (
                  <p style={{ fontWeight: "bold" }}>Brak</p>
                )}
              </Stack>
            </Stack>
            <Stack display={"flex"} flexDirection={"row"} gap={1}>
              {requirement?.slots?.length > 0 ? (
                getHoursAndDays.map((detail, index) => {
                  const [day, hour] = detail.split('&')
                  return <CustomChip label={`${day} - ${hour}`}  />
                })
              ) : (
                <p style={{ fontWeight: "bold" }}>Brak</p>
              )}
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
    );
  },
  BLOCKING_LESSON_AT_THE_SAME_TIME: (requirement) => {
    return (
      <Accordion style={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${requirement._id.$oid}-content`}
          id={`${requirement._id.$oid}-header`}
        >
          <Typography variant="h5">
            {translateRequirementType(requirement.type).toUpperCase()}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            gap={1}
          >
            <Stack>
              <Typography variant="body1" fontWeight={'bold'}>
                Typ
              </Typography>
              <CustomChip
                label={translateRequirementType(requirement.attType)}
                color="secondary"
              />
            </Stack>

            <Stack>
              <Typography variant="body1" fontWeight={'bold'}>
                {pluralizeCommonWords(translateRequirementType(requirement.attType), requirement.idsDetails?.length)}
              </Typography>
                <Stack display={"flex"} flexDirection={"row"} gap={1}>
                  {requirement?.idsDetails?.length > 0 ? (
                    requirement?.idsDetails?.map((detail) => (
                      <CustomChip label={requirement.attType === "UNIQUE_ID" ? `${detail.lessonType.name} - ${detail.studentClass.name}` : detail.name} color="info" />
                    ))
                  ) : (
                    <p style={{ fontWeight: "bold" }}>Brak</p>
                  )}
                </Stack>
            </Stack>
            <Stack>
              <Typography variant="body1" fontWeight={'bold'}>
                {pluralize(requirement.groupsDetails?.length, "Grupa", "Grupy")}
              </Typography>

              <Stack display={"flex"} flexDirection={"row"} gap={1}>
                {requirement?.groupsDetails?.length > 0 ? (
                  findClassesForGroups(requirement.groupsDetails, "GROUP")?.map(
                    (detail) => <CustomChip label={detail.name} color="warning" />
                  )
                ) : (
                  <p style={{ fontWeight: "bold" }}>Brak</p>
                )}
              </Stack>
            </Stack>
    

          </Stack>
        </AccordionDetails>
      </Accordion>
    );
  },
  FORBIDDEN_LESSON_ON_THE_SAME_DAY: (requirement) => {
    return (
      <Accordion style={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${requirement._id.$oid}-content`}
          id={`${requirement._id.$oid}-header`}
        >
          <Typography variant="h5">
            {translateRequirementType(requirement.type).toUpperCase()}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            gap={1}
          >
            <Stack>
              <Typography variant="body1" fontWeight={'bold'}>
                Typ
              </Typography>
              <CustomChip
                label={translateRequirementType(requirement.attType)}
              />
            </Stack>
      
            <Stack>
              <Typography variant="body1" fontWeight={'bold'}>
                {pluralizeCommonWords(translateRequirementType(requirement.attType), requirement.idsDetails?.length)}
              </Typography>
              <Stack display={"flex"} flexDirection={"row"} gap={1}>
                {requirement?.idsDetails?.length > 0 ? (
                  requirement?.idsDetails?.map((detail) => (
                    <CustomChip label={requirement.attType === "UNIQUE_ID" ? `${detail.lessonType.name} - ${detail.studentClass.name}` : detail.name} color="info" />
                  ))
                ) : (
                  <p style={{ fontWeight: "bold" }}>Brak</p>
                )}
              </Stack>
            </Stack>
           <Stack>
            <Typography variant="body1" fontWeight={'bold'}>
              {pluralize(requirement.groupsDetails?.length, "Grupa", "Grupy")}
            </Typography>
            <Stack display={"flex"} flexDirection={"row"} gap={1}>
                {requirement?.groupsDetails?.length > 0 ? (
                  findClassesForGroups(requirement.groupsDetails, "GROUP").map(
                    (detail) => <CustomChip label={detail.name} />
                  )
                ) : (
                  <p style={{ fontWeight: "bold" }}>Brak</p>
                )}
             </Stack>
           </Stack>
            
          </Stack>
        </AccordionDetails>
      </Accordion>
      )
    },
  EDGE_LESSON: (requirement) => (
    <Accordion style={{ width: "100%" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${requirement._id.$oid}-content`}
        id={`${requirement._id.$oid}-header`}
      >
        <Typography variant="h5">
          {translateRequirementType(requirement.type).toUpperCase()}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
          gap={1}
        >
          <Stack>
            <Typography variant="body1" fontWeight={'bold'}>
              Typ lekcji na krawędzi
            </Typography>
            <Typography variant="subtitle2" >
              {translateRequirementType(requirement.edgeType)}
            </Typography>
          </Stack>

          <Stack>
            <Typography variant="body1" fontWeight={'bold'}>
              {pluralizeCommonWords(translateRequirementType(requirement.attType), requirement.idsDetails?.length)}
            </Typography>
            <Stack display={"flex"} flexDirection={"row"} gap={1}>
              {requirement?.idsDetails?.length > 0 ? (
                requirement?.idsDetails?.map((detail, index) => (
                  <CustomChip  key={`${detail.name}-${index}`} label={requirement.attType === "UNIQUE_ID" ? `${detail.lessonType.name} - ${detail.studentClass.name}` : detail.name} color="info" />
                ))
              ) : (
                <p style={{ fontWeight: "bold" }}>Brak</p>
              )}
            </Stack>
          </Stack>
         
         <Stack>  
          <Typography variant="body1" fontWeight={'bold'}>
            {pluralize(requirement.groupsDetails?.length, "Grupa", "Grupy")}
          </Typography>
          <Stack display={"flex"} flexDirection={"row"} gap={1}>
              {requirement.groupsDetails?.length > 0 ? (
                findClassesForGroups(requirement.groupsDetails, "GROUP").map(
                  (detail, index) => <CustomChip key={`${detail.name}-${index}`} label={detail.name} />
                )
              ) : (
                <p style={{ fontWeight: "bold" }}>Brak</p>
              )}
            </Stack>
         </Stack>
          
        </Stack>
      </AccordionDetails>
    </Accordion>
  ),
  LESSON_DISTRIBUTION: (requirement) => {
    const groupsIdsCount = requirement.groupsIds?.length;
    return (
      <Accordion style={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${requirement._id.$oid}-content`}
          id={`${requirement._id.$oid}-header`}
        >
          <Typography variant="h5">
            {translateRequirementType(requirement.type).toUpperCase()}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            gap={1}
          >
            <Stack display={"flex"} justifyContent={"flex-start"}>
              <Typography variant="body1" fontWeight={"bold"}>
                Typ rozkładu lekcji
              </Typography>
              <Typography variant={"subtitle2"}>
                {translateRequirementType(requirement.distType)}
              </Typography>
            </Stack>
            <Stack>
              <Typography variant={"body1"} fontWeight={"bold"}>
                {pluralize(groupsIdsCount, "Klasa", "Klasy")}
              </Typography>
              <Stack display={"flex"} flexDirection={"row"} gap={1}>
                {requirement?.groupsDetails?.length > 0 ? (
                  findClassesForGroups(requirement.groupsDetails, "GROUP").map(
                    (detail) => <CustomChip label={detail.name} />
                  )
                ) : (
                  <p style={{ fontWeight: "bold" }}>Brak</p>
                )}
              </Stack>
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
    )
  },
  LABELED_LESSONS_PER_DAY: (requirement) => {
    return (
      <Accordion style={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${requirement._id.$oid}-content`}
          id={`${requirement._id.$oid}-header`}
        >
          <Typography variant={"h5"}>
            {translateRequirementType(requirement.type).toUpperCase()}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            gap={1}
          >
            <Stack display={"flex"}>
              <Typography variant={"body1"} fontWeight={"bold"}>
                {pluralize(requirement.groupsIds?.length, "Grupa", "Grupy")}
              </Typography>
              <Stack display={"flex"} flexDirection={"row"} gap={1}>
                {requirement?.groupsDetails?.length > 0 ? (
                  findClassesForGroups(requirement.groupsDetails, "GROUP").map(
                    (detail) => <CustomChip label={detail.name} />
                  )
                ) : (
                  <p style={{ fontWeight: "bold" }}>Brak</p>
                )}
              </Stack>
            </Stack>

            <Stack>
              <Typography variant={"body1"} fontWeight={"bold"}>
                Mask. ilość
              </Typography>
              <Typography variant={"subtitle2"}>
                <CustomChip>{requirement.maxCnt}</CustomChip>
              </Typography>
            </Stack>

            <Stack display={"flex"} flexDirection={"column"} gap={1}>
              <Typography variant={"body1"} fontWeight={"bold"}>
                Etykieta
              </Typography>
              {requirement?.labelDetails?.length > 0 ? (
                requirement?.labelDetails?.map((detail) => (
                  <CustomChip label={detail.name} />
                ))
              ) : (
                <p style={{ fontWeight: "bold" }}>Brak</p>
              )}
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
    );
  },
  LESSON_START: (requirement) => {
    const lessonStartHoursData = toJS(SchoolInfoStore.schoolPlanConfig.data[0]);
    return (
      <Accordion style={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${requirement._id.$oid}-content`}
          id={`${requirement._id.$oid}-header`}
        >
          <Typography variant={"h5"}>
            {translateRequirementType(requirement.type).toUpperCase()}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            gap={1}
          >
          <Stack display={"flex"} flexDirection={"column"} flexWrap={"wrap"} >
            <Typography variant={"body1"} fontWeight={"bold"}>
              Godzina rozpoczęcia zajęć
            </Typography>
            <Stack display={"flex"} flexDirection={"row"} gap={1}>
              {requirement?.lessonsStarts?.length > 0 ? (
                requirement?.lessonsStarts?.map((detail, index) => (
                  <CustomChip label={getLessonStartDataName(lessonStartHoursData.daysNames, lessonStartHoursData.slotsNames, detail, index)}  />
                ))
              ) : (
                <p style={{ fontWeight: "bold" }}>Brak</p>
              )}
            </Stack>
          </Stack>

          <Stack display={"flex"} flexWrap={"wrap"}>
            <Typography variant={"body1"} fontWeight={"bold"}>
              {pluralize(requirement.groupsIds?.length, "Grupa", "Grupy")}
            </Typography>
            <Stack display={"flex"} flexDirection={"row"} gap={1} flexWrap={"wrap"}>
              {requirement?.groupsDetails?.length > 0 ? (
                findClassesForGroups(requirement.groupsDetails, "GROUP").map(
                  (detail) => <CustomChip label={detail.name} />
                )
              ) : (
                <p style={{ fontWeight: "bold" }}>Brak</p>
              )}
            </Stack>
          </Stack>
        </Stack>
        </AccordionDetails>
      </Accordion>
    );
  },
};

export const renderCorrectRequirement = (requirement) => {
  return possibleRequirements[requirement.type](requirement);
};

const translateRequirementType = (type) => {
  switch (type) {
    case "PREFERENCE":
      return "Preferencja";
    case "BLOCKING_LESSON_AT_THE_SAME_TIME":
      return "Zablokowanie lekcji w tym samym czasie";
    case "FORBIDDEN_LESSON_ON_THE_SAME_DAY":
      return "Zablokowanie lekcji w tym samym dniu";
    case "EDGE_LESSON":
      return "Lekcja krawędziowa";
    case "LESSON_DISTRIBUTION":
      return "Rozkład lekcji";
    case "LABELED_LESSONS_PER_DAY":
      return "Oznaczone lekcje w ciągu dnia";
    case "LESSON_START":
      return "Początek lekcji";
    case "LESSON_TYPE":
      return "Przedmiot";
    case "LESSON_LABEL":
      return "Oznaczenie lekcji";
    case "EVEN":
      return "Parzyste";
    case "SPECIFIC":
      return "Specyficzne";
    case "RANGE":
      return "Zakres";
    case "TEACHER":
      return "Nauczyciel";
    case "GROUP":
      return "Grupa";
    case "CLASSROOM":
      return "Sala";
    case "UNIQUE_ID":
      return "Lekcja";
    case "BOTH":
      return "Obie krawędzie"
    case "FIRST":
      return "Pierwsza krawędź"
    case "LAST":
      return "Ostatnia krawędź"
    default:
      return type;
  }
};
