import React from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Chip,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const LessonsListAccordion = ({ 
  classesData, 
  choosenPreferenceType, 
  handleLessonChange, 
  selectedLessons 
}) => {
  if (!classesData || classesData.length === 0) {
    return <Typography>Brak klas</Typography>;
  }

  return (
    <>
      <Typography variant="h5">Lista dostępnych klas:</Typography>
      {classesData.sort((a, b) => a.name.localeCompare(b.name)).map((cl) => {
          const classLessons = choosenPreferenceType.filter((lesson) => lesson.student_class_id.$oid === cl._id.$oid);
          const notSelectedLessons = classLessons.filter((lesson) => !selectedLessons.some((selected) => selected._id.$oid === lesson._id.$oid));
          if(notSelectedLessons.length === 0) return null;

          return (
            <Accordion key={`accordion-${cl._id.$oid}`}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${cl._id.$oid}-content`}
                id={`panel-${cl._id.$oid}-header`}
              >
                Lekcje klasy: {cl.name}
              </AccordionSummary>
              <AccordionDetails>
                <Stack gap={1}>
                  {notSelectedLessons.sort((a, b) =>a.lessonType.name.localeCompare(b.lessonType.name))
                    .map((lesson, idx) => (
                      <Stack
                        key={`lesson-${lesson._id?.$oid ?? idx}`}
                        gap={2}
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <Typography variant="subtitle1">
                          {lesson?.lessonType?.name}
                        </Typography>
                        <Stack gap={1} display="flex" flexDirection="row">
                          {lesson?.groupDetails?.map((group, i) => (
                            <Chip key={`group-${i}`} label={group?.name} />
                          ))}
                        </Stack>
                        <Stack gap={1} display="flex" flexDirection="row">
                          {lesson?.allowedClassroomsDetails?.map(
                            (label, index) =>
                              index < 2 && (
                                <Chip
                                  key={`allowed-${index}`}
                                  label={label?.name}
                                  color="secondary"
                                />
                              )
                          )}
                        </Stack>
                        <Button onClick={() => handleLessonChange({...lesson, className: cl.name})}>
                          +
                        </Button>
                      </Stack>
                    ))}
                </Stack>
              </AccordionDetails>
            </Accordion>
          );
        })}
    </>
  );
};

export default LessonsListAccordion;
