import {Typography} from "@mui/material";

export const pluralize = (count, singleWord, pluralWord, secondPluralWord) => {
  if (!secondPluralWord) {
    if (count === 1) {
      return singleWord;
    } else {
      return pluralWord;
    }
  }

  if (count === 1) {
    return singleWord;
  } 

  if (count > 10 && count < 20) {
    return  secondPluralWord;
  } 

  const lastDigit = Number(count.toString().slice(-1));

  if (lastDigit > 1 && lastDigit < 5) {
    return pluralWord;
  } else if (lastDigit > 4) {
    return secondPluralWord;
  }
}

export const pluralizeCommonWords = (word, counter) => {
  const lowerCaseWord = word.toLowerCase();

  if (lowerCaseWord === "przedmiot") {
    if(counter > 1) {
      return "Przedmioty";
    } else {
      return "Przedmiot";
    }
  }

  if (lowerCaseWord === "grupa") {
    if(counter > 1) {
      return "Grupy";
    } else {
      return "Grupa";
    }
  }

  if (lowerCaseWord === "nauczyciel") {
    if(counter > 1) {
      return "Nauczyciele";
    } else {
      return "Nauczyciel";
    }
  }

  if (lowerCaseWord === "klasa") {
    if(counter > 1) {
      return "Klasy";
    } else {
      return "Klasa";
    }
  }

  if (lowerCaseWord === "sala") {
    if(counter > 1) {
      return "Sale";
    } else {
      return "Sala";
    }
  }

  if (lowerCaseWord === "dzień") {
    if(counter > 1) {
      return "Dni";
    } else {
      return "Dzień";
    }
  }

  if (lowerCaseWord === "slot") {
    if(counter > 1) {
      return "Sloty";
    } else {
      return "Slot";
    }
  }

  if (lowerCaseWord === "wymaganie") {
    if(counter > 1) {
      return "Wymagania";
    } else {
      return "Wymaganie";
    }
  }

  if (lowerCaseWord === "preferencja") {
    if(counter > 1) {
      return "Preferencje";
    } else {
      return "Preferencja";
    }
  }

  if (lowerCaseWord === "etykieta") {
    if(counter > 1) {
      return "Etykiety";
    } else {
      return "Etykieta";
    }
  }

  if (lowerCaseWord === "oznaczenie lekcji") {
    if(counter > 1) {
      return "Oznaczenia lekcji";
    } else {
      return "Oznaczenie lekcji";
    }
  }

  if (lowerCaseWord === "lekcja") {
    if(counter > 1) {
      return "Lekcje";
    } else {
      return "Lekcja";
    }
  }
}

export const getLessonStartDataName = (days, slots, detail, index) => {
  let dayNamesToDisplay;
  const dayName = days[index];
  const slotName = slots[detail - 1];
  if (dayName && slotName) {
    dayNamesToDisplay = <Typography variant={"caption"}>{dayName} <br/> {slotName}</Typography>;
  }
  // console.log({
  //   days,slots,detail,index
  // })


  return dayNamesToDisplay;
}

export const getLessonsStartPreferencesHours = ({slotsNames, daysNames, slots}) => {
  const hourlySchedule = {};
  const schedule = [];

  for (let i = 0; i < daysNames.length; i++) {
    const dayMinRange = i * slotsNames.length;
    const dayMaxRange = (i + 1) * slotsNames.length;
  
    for (let j = 0; j < slots.length; j++) {
      if (slots[j] >= dayMinRange && slots[j] < dayMaxRange) {
        const slotIndex = slots[j] - dayMinRange;
  
        // Poprawna interpolacja stringa
        schedule.push(`${daysNames[i]}&${slotsNames[slotIndex]}`);
      }
    }
  }
  
  return schedule;
}