import React, { useState } from "react";
import SchoolInfoStore from "../../../../../mobx/SchoolInfoStore";
import {
  Stack,
  Typography,
  OutlinedInput,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Chip,
  Button,
  ListSubheader,
  Slider,
} from "@mui/material";
import { toJS } from "mobx";
import { formListConfig } from "../../requirementsConfig";
import RequirementsStore from "../../../../../mobx/RequirementsStore";

const LabeledLessonsPerDayForm = () => {
  const { classes, labels } = toJS(SchoolInfoStore);
  const { numOfSlotsPerDay: slots } = toJS(
    SchoolInfoStore?.schoolPlanConfig?.data?.[0]
  );
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState(
    labels?.data?.[0]._id.$oid
  );
  const [maxCnt, setMaxCnt] = useState(1);

  const handleSubmit = async () => {
    const data = {
      type: "LABELED_LESSONS_PER_DAY",
      label: { $oid: selectedLabel },
      groupsIds: selectedGroups.map((id) => ({ $oid: id })),
      maxCnt
    };

    const rsp = await RequirementsStore.postRequirement(data);
    console.log(rsp)

  };

  const handleLabelChange = (e) => {
    setSelectedLabel(e.target.value);
  };

  const handleGroupsChange = (e) => {
    const {
      target: { value },
    } = e;
    setSelectedGroups(typeof value === "string" ? value.split(",") : value);
  };

  const handleSelectedMaxCnt = (e) => {
    const newSlots = e.target.value;
    setMaxCnt(newSlots);
  };

  return (
    <Stack gap={1}>
      <FormControl fullWidth>
        <InputLabel id="label">Wybierz etykietę</InputLabel>
        <Select
          labelId="label"
          value={selectedLabel}
          onChange={handleLabelChange}
          label="Wybierz etykietę"
        >
          {labels?.data?.map((label) => (
            <MenuItem key={label._id.$oid} value={label._id.$oid}>
              {label.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Stack>
        <Typography variant="h6">Maksymalna ilość lekcji:</Typography>
        <FormControl fullWidth>
          <Slider
            value={maxCnt}
            onChange={handleSelectedMaxCnt}
            step={1}
            marks
            min={1}
            max={slots}
            valueLabelDisplay="auto"
          />
        </FormControl>
      </Stack>

      <Stack gap={1}>
        <FormControl fullWidth>
          <InputLabel id="chooseGroupsLabel">Wybierz grupy</InputLabel>
          <Select
            labelId="chooseGroupsLabel"
            id="chooseGroups"
            multiple
            value={selectedGroups}
            input={<OutlinedInput label="Wybierz grupy" />}
            onChange={handleGroupsChange}
            label="Wybierz grupy"
            MenuProps={formListConfig.MENU_PROPS}
            renderValue={(selected) =>
              selected
                .map((id) => {
                  const group = classes.data
                    .flatMap((cl) => cl.groups)
                    .find((g) => g._id.$oid === id);
                  const className = classes.data.find((cl) =>
                    cl.groups.some((g) => g._id.$oid === id)
                  )?.name;
                  return group && className
                    ? `${className} - ${group.name}`
                    : "";
                })
                .join(", ")
            }
          >
            {classes.data.map((cl) => [
              <ListSubheader key={`class-${cl._id.$oid}`}>
                {cl.name}
              </ListSubheader>,
              ...cl.groups.map((group) => (
                <MenuItem
                  key={`group-${group._id.$oid}`}
                  value={group._id.$oid}
                >
                  <Checkbox
                    checked={selectedGroups.indexOf(group._id.$oid) > -1}
                  />
                  {group.name}
                </MenuItem>
              )),
            ])}
          </Select>
        </FormControl>
      </Stack>
      {selectedGroups.length > 0 && (
        <>
          <Stack direction="row" flexWrap="wrap" gap={1} mb={1}>
            {selectedGroups.map((id) => {
              const group = classes.data
                .flatMap((cl) => cl.groups)
                .find((g) => g._id.$oid === id);
              const className = classes.data.find((cl) =>
                cl.groups.some((g) => g._id.$oid === id)
              )?.name;
              return (
                <Chip
                  key={`group-${group._id.$oid}`}
                  label={`${className} - ${group?.name}`}
                  onDelete={() => {
                    setSelectedGroups((groups) =>
                      groups.filter((group) => group !== id)
                    );
                  }}
                />
              );
            })}
          </Stack>
          <Button variant="contained" onClick={handleSubmit}>
            Zapisz
          </Button>
        </>
      )}
    </Stack>
  );
};

export default LabeledLessonsPerDayForm;
