import React from 'react';
import {CircularProgress, Typography} from '@mui/material';
import styles from './LoadingBar.module.scss';
import {createPortal} from 'react-dom';

const LoadingBar = ({progress}) => {
  return (
    <div className={styles.loadingBar}>
      <div className={styles.content}>
        <CircularProgress value={progress && progress}/>
        <Typography variant="caption" mt={2}>Trwa ładowanie</Typography>
        <Typography variant="caption">Proszę czekać...</Typography>
        {/* <CircularProgress value={progress && progress}/>
        {progress && <p>{progress}%</p>} */}
      </div>
    </div>
  )
}

const PortalLoadingBar = () => {
  return (
    createPortal(<LoadingBar />, document.body)
  )
}

export default PortalLoadingBar;