import React from "react";
import {
  Select,
  MenuItem,
  ListSubheader,
  Checkbox,
  OutlinedInput,
} from "@mui/material";
import { formListConfig } from "../../components/Profile/Requirements/requirementsConfig";

const GroupsMultiSelect = ({
  id,
  labelId,
  label,
  values,
  initialValues,
  cb,
}) => {
  return (
    <Select
      id={id}
      labelId={labelId}
      label={label}
      multiple
      multiline
      rows={6}
      value={values}
      input={<OutlinedInput label={label} />}
      onChange={cb}
      renderValue={(selected) => 
        selected.map((id) => {
          const group = initialValues.flatMap((cl) => cl.groups).find((g) => g._id.$oid === id);
          const className = initialValues.find((cl) => cl.groups.some((g) => g._id.$oid === id))?.name;

          return group && className ? `${className} - ${group.name}` : "";
        })
        .join(", ")
      }
      MenuProps={formListConfig.MENU_PROPS}
    >
      {initialValues.map((preference) => [
        <ListSubheader key={`class-${preference._id.$oid}`}>
          {preference.name}
        </ListSubheader>,
        ...preference.groups.map((group) => (
          <MenuItem key={`group-${group._id.$oid}`} value={group._id.$oid}>
            <Checkbox checked={values.indexOf(group._id.$oid) > -1} />
            {group.name}
          </MenuItem>
        )),
      ])}
    </Select>
  );
};

export default GroupsMultiSelect;
