import React from "react";
import styles from "./CustomChip.module.scss";
import { Typography } from "@mui/material";

const CustomChip = ({ label, color, children }) => {
  
  return (
    <div className={styles.customChip}>
      <Typography variant="caption">{label ? label : children}</Typography>
    </div>
  );
};

export default CustomChip;
