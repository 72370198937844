import { MenuItem, Select, Checkbox, ListItemText } from "@mui/material";
import React from 'react';
import { formListConfig } from "../../components/Profile/Requirements/requirementsConfig";

const BasicMultiselect = ({
  id,
  values,
  selectedValues,
  label,
  cb
}) => {
  const handleNoGroupSelected = (selected) => {
    const names = selected.map((sel) => {
        const found = values.find((opt) => opt._id.$oid === sel);
        return found ? found.name : "";
      })
      .filter((name) => !!name);
    return names.join(", ");
  }

  return (
    <Select
    id={id}
    labelId={`${id}Label`}
    multiple
    label={label}
    value={selectedValues}
    onChange={cb}
    MenuProps={formListConfig.MENU_PROPS}
    renderValue={(selected) => handleNoGroupSelected(selected)}
  >
    {values.map((val) => {
      return (
        <MenuItem key={val._id.$oid} value={val._id.$oid}>
          <Checkbox checked={selectedValues.indexOf(val._id.$oid) > -1} />
          <ListItemText primary={val.name} />
        </MenuItem>
      );
    })}
  </Select>
  );
};

export default BasicMultiselect;