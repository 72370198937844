import { action, computed, makeAutoObservable, observable, toJS } from "mobx";
import DataService from "../services/DataService";
import ErrorHandler from "../services/ErrorHandler";
import AuthStore from "./AuthStore";

class SchoolInfoStore {
  schoolName = "";
  schoolPlanId;

  @observable schoolPlanConfig = {
    data: [],
    loading: false,
    error: null,
  };

  @observable classRooms = {
    data: [],
    loading: false,
    error: null,
  };

  @observable teachers = {
    data: [],
    loading: false,
    error: null,
  };

  @observable subjects = {
    data: [],
    loading: false,
    error: null,
  };

  @observable labels = {
    data: [],
    loading: false,
    error: null,
  };

  @observable classes = {
    data: [],
    loading: false,
    error: null,
  };

  @observable groups = {
    data: [],
    loading: false,
    error: null,
  };

  @observable lessons = {
    data: [],
    loading: false,
    error: null,
  };

  constructor() {
    makeAutoObservable(this);
  }

  @action
  setLoading(type, boolean) {
    this[type].loading = boolean;
  }

  @action
  setSchoolName(name) {
    this.schoolName = name;
  }

  @action
  setSchoolPlanId(id) {
    this.schoolPlanId = id;
  }

  @action
  setSchoolPlanConfigState(data) {
    this.schoolPlanConfig.data = data;
  }

  @action
  setClassRoomsState(data) {
    this.classRooms.data = data;
  }

  @action
  setClassesState(data) {
    this.classes.data = data;
  }

  @action
  setLabelsState(data) {
    this.labels.data = data;
  }

  @action
  setSubjectsState(data) {
    this.subjects.data = data;
  }

  @action
  setTeachersState(data) {
    this.teachers.data = data;
  }

  @action
  setGroupsState(data) {
    this.groups.data = data;
  }

  @action
  setLessonsDataState(data) {
    this.lessons.data = data;
  }

  getSchoolPlanIdForRequirements() {
    return this.schoolPlanId;
  }

  // SCHOOL PLAN CONFIG
  @action
  async getSchoolPlanConfig() {
    this.setLoading("schoolPlanConfig", true);
    const data = await DataService.getSchoolPlanConfig(this.schoolPlanId);
    this.setLoading("schoolPlanConfig", false);

    if (!data.error) {
      this.setSchoolPlanConfigState(data);
      this.setClassRoomsState(data[0].classrooms);
      this.setClassesState(data[0].studentClasses);
      this.setLabelsState(data[0].lessonLabels);
      this.setSubjectsState(data[0].lessonTypes);
      this.setTeachersState(data[0].teachers);
      this.setGroupsState(data[0].classGroups);
      this.setLessonsDataState(data[0].lessons);
      this.setSchoolPlanId(data[0]._id);
      return;
    }

    this.schoolPlanConfig.error = {
      message: data.errorMessage,
      status: data.status,
      error: data.error,
    };
  }

  @action
  async addItem(data, type, polishType, endpoint) {
    if (
      this[type].data.some(
        (item) => item.name.toLowerCase() === data.name.toLowerCase()
      )
    ) {
      const error = new ErrorHandler("Error", 400);
      return error.checkErrorStatus(
        `${polishType.toUpperCase()} o tej nazwie już istnieje`
      );
    }

    const newData = {
      school_plan_config_id: this.schoolPlanId,
      user_id: AuthStore.user.id,
      ...data,
    };

    const response = await DataService.postData(newData, endpoint);
    if (response?.error) {
      return response;
    }

    this[type].data = [...this[type].data, response];
    return response;
  }

  @action
  async addGroupsToClass(data) {
    for (const c of data) {
      const newC = {
        ...c,
        school_plan_config_id: this.schoolPlanId,
      };

      const response = await DataService.postData(newC, "classGroup");
      if (response?.error) {
        return response;
      }

      this.groups.data = [...this.groups.data, response];
    }
    return { success: "Grupy zostały dodane pomyślnie" };
  }

  @action
  async deleteGroups(groupsToDelete) {
    for (const group of groupsToDelete) {
      const response = await DataService.deleteData("classGroup", group._id);
      if (response.error) {
        return response;
      }

      const newGroups = this.groups.data.filter((g) => g._id !== group._id);
      this.groups.data = newGroups;
    }
  }

  @action
  async editClass(data, endpoint, id) {
    console.log(data, endpoint);
    if (
      this.classes.data.some(
        (item) =>
          item.name.toLowerCase() === data.name.toLowerCase() &&
          item._id !== data._id
      )
    ) {
      const error = new ErrorHandler("Error", 400);
      return error.checkErrorStatus(`KLASA o tej nazwie już istnieje`);
    }

    const response = await DataService.editData(data, endpoint, data._id);

    if (response?.error) {
      return response;
    }

    const index = this.classes.data.findIndex((item) => item._id === data._id);
    this.classes.data[index] = data;
    return response;
  }

  @action
  async editItem(data, type, polishType, endpoint) {
    if (
      this[type].data.some(
        (item) =>
          item.name.toLowerCase() === data.name.toLowerCase() &&
          item._id !== data._id
      )
    ) {
      const error = new ErrorHandler("Error", 400);
      return error.checkErrorStatus(
        `${polishType.toUpperCase()} o tej nazwie już istnieje`
      );
    }

    const response = await DataService.editData(data, endpoint, data._id);
    if (response?.error) {
      return response;
    }

    const index = this[type].data.findIndex((item) => item._id === data._id);
    this[type].data[index] = data;
  }

  @action
  async deleteItem(id, type, endpoint) {
    const response = await DataService.deleteData(endpoint, id);

    if (response.error) {
      return response;
    }

    const newItems = this[type].data.filter(
      (item) => item._id.$oid !== id.$oid
    );

    this[type].data = newItems;
    return response;
  }

  @action
  async addManyItems(items, type, polishTypes, endpoint) {
    let wrongItems = [];
    const response = await Promise.all(
      items.map(async (item) => {
        const response = await this.addItem(
          { name: item },
          type,
          polishTypes,
          endpoint
        );

        if (response?.error) {
          wrongItems.push(item);
        }
        return response;
      })
    );

    if (wrongItems.length === items.length) {
      const error = new ErrorHandler("Error", 400);
      return error.checkErrorStatus(
        `${polishTypes.toUpperCase()} nie zostały dodane, spróbuj ponownie`
      );
    }

    if (wrongItems.length > 0) {
      return {
        warning: `${polishTypes.toUpperCase()} (${wrongItems.join(
          ", "
        )}) już istnieją. Reszta została dodana pomyślnie`,
      };
    }
    return response;
  }

  @action
  async addLesson(data, dataForMobx) {
    const newData = {
      school_plan_config_id: this.schoolPlanId,
      user_id: AuthStore.user.id,
      ...data,
    };

    const response = await DataService.postData(newData, "lesson");

    const newDataMobx = {
      _id: response._id,
      school_plan_config_id: this.schoolPlanId,
      user_id: AuthStore.user.id,
      ...response,
      ...dataForMobx,
    };

    if (response.error) {
      return response;
    }

    this.setLessonsState(newDataMobx);
    return response;
  }

  @action
  async editLesson(data, dataForMobx, id) {
    this.lessons.loading = true;
    const response = await DataService.editData(data, "lesson", id);
    this.lessons.loading = false;
    if (response?.error) {
      return response;
    }

    const newDataMobx = {
      _id: response._id,
      school_plan_config_id: this.schoolPlanId,
      user_id: AuthStore.user.id,
      ...response,
      ...toJS(dataForMobx),
    };

    const index = this.lessons.data.findIndex((lesson) => lesson._id === id);
    this.editLessonsState(newDataMobx, index);
    return response;
  }

  @action
  editLessonsState(lesson, index) {
    this.lessons.data[index] = lesson;
  }

  @action
  setLessonsState(lessons) {
    this.lessons.data = [...this.lessons.data, lessons];
  }

  @action
  async deleteClassromFromLesson(lessonId, classroomId) {
    const foundLesson = this.lessons.data.find(
      (lesson) => lesson._id.$oid === lessonId.$oid
    );

    const updatedClassroomsDetails =
      foundLesson.allowedClassroomsDetails.filter(
        (classroom) => classroom._id.$oid !== classroomId
      );

    const updatedClassrooms = foundLesson.allowedClassrooms.filter(
      (classroom) => classroom.$oid !== classroomId
    );

    const newData = {
      ...toJS(foundLesson),
      allowedClassroomsDetails: updatedClassroomsDetails,
      allowedClassrooms: updatedClassrooms,
    };

    const data = {
      lesson_type_id: newData.lesson_type_id,
      size: newData.size,
      amountPerWeek: newData.amountPerWeek,
      teacher_id: newData.teacher_id,
      lesson_label_id: newData.lesson_label_id,
      allowedClassrooms: newData.allowedClassrooms,
      groups: newData.groups,
      student_class_id: newData.student_class_id,
    };

    this.lessons.loading = true;
    const response = await DataService.editData(data, "lesson", lessonId);
    this.lessons.loading = false;
    if (response?.error) {
      return response;
    }

    const newDataMobx = {
      _id: response._id,
      school_plan_config_id: this.schoolPlanId,
      user_id: AuthStore.user.id,
      ...response,
      ...toJS(newData),
    };

    const index = this.lessons.data.findIndex(
      (lesson) => lesson._id === lessonId
    );
    this.editLessonsState(newDataMobx, index);
    return response;
  }

  @action
  setLessonsAfterCopying(lessons) {
    this.lessons.data = lessons;
  }

  @action
  async deleteGroupFromLesson(lessonId, groupId) {
    const foundLesson = this.lessons.data.find(
      (lesson) => lesson._id.$oid === lessonId.$oid
    );

    const updatedGroupsDetails = foundLesson.groupDetails.filter(
      (classroom) => classroom._id.$oid !== groupId
    );

    const updatedGroups = foundLesson.groups.filter(
      (classroom) => classroom.$oid !== groupId
    );

    const newData = {
      ...toJS(foundLesson),
      groupDetails: updatedGroupsDetails,
      groups: updatedGroups,
    };

    const data = {
      lesson_type_id: newData.lesson_type_id,
      size: newData.size,
      amountPerWeek: newData.amountPerWeek,
      teacher_id: newData.teacher_id,
      lesson_label_id: newData.lesson_label_id,
      allowedClassrooms: newData.allowedClassrooms,
      groups: newData.groups,
      student_class_id: newData.student_class_id,
    };

    this.lessons.loading = true;
    const response = await DataService.editData(data, "lesson", lessonId);
    this.lessons.loading = false;
    if (response?.error) {
      return response;
    }

    const newDataMobx = {
      _id: response._id,
      school_plan_config_id: this.schoolPlanId,
      user_id: AuthStore.user.id,
      ...response,
      ...toJS(newData),
    };

    const index = this.lessons.data.findIndex(
      (lesson) => lesson._id === lessonId
    );
    this.editLessonsState(newDataMobx, index);
    return response;
  }

  @action
  async updateLesson(lessonId, selectedSubject) {
    const foundLesson = this.lessons.data.find(
      (lesson) => lesson._id === lessonId
    );

    const newData = {
      ...toJS(foundLesson),
      lessonType: selectedSubject,
    };

    const data = {
      lesson_type_id: selectedSubject._id,
      size: newData.size,
      amountPerWeek: newData.amountPerWeek,
      teacher_id: newData.teacher_id,
      lesson_label_id: newData.lesson_label_id,
      allowedClassrooms: newData.allowedClassrooms,
      groups: newData.groups,
      student_class_id: newData.student_class_id,
    };

    this.lessons.loading = true;
    const response = await DataService.editData(data, "lesson", lessonId);
    this.lessons.loading = false;
    if (response?.error) {
      return response;
    }

    const newDataMobx = {
      _id: response._id,
      school_plan_config_id: this.schoolPlanId,
      user_id: AuthStore.user.id,
      ...response,
      ...toJS(newData),
    };

    const index = this.lessons.data.findIndex(
      (lesson) => lesson._id === lessonId
    );
    this.editLessonsState(newDataMobx, index);
    return response;
  }

  @action
  async updateSize(lessonId, selectedSize) {
    const foundLesson = this.lessons.data.find(
      (lesson) => lesson._id === lessonId
    );
    const newData = {
      ...toJS(foundLesson),
      size: selectedSize,
    };
    const data = {
      lesson_type_id: newData.$oid,
      size: selectedSize,
      amountPerWeek: newData.amountPerWeek,
      teacher_id: newData.teacher_id,
      lesson_label_id: newData.lesson_label_id,
      allowedClassrooms: newData.allowedClassrooms,
      groups: newData.groups,
      student_class_id: newData.student_class_id,
    };

    this.lessons.loading = true;
    const response = await DataService.editData(data, "lesson", lessonId);
    this.lessons.loading = false;
    if (response?.error) {
      return response;
    }

    const newDataMobx = {
      _id: response._id,
      school_plan_config_id: this.schoolPlanId,
      user_id: AuthStore.user.id,
      ...response,
      ...toJS(newData),
    };

    const index = this.lessons.data.findIndex(
      (lesson) => lesson._id === lessonId
    );
    this.editLessonsState(newDataMobx, index);
    return response;
  }

  @action
  async updateAmount(lessonId, selectedAmount) {
    const foundLesson = this.lessons.data.find(
      (lesson) => lesson._id === lessonId
    );
    const newData = {
      ...toJS(foundLesson),
      amountPerWeek: selectedAmount,
    };

    const data = {
      lesson_type_id: newData.$oid,
      size: newData.size,
      amountPerWeek: selectedAmount,
      teacher_id: newData.teacher_id,
      lesson_label_id: newData.lesson_label_id,
      allowedClassrooms: newData.allowedClassrooms,
      groups: newData.groups,
      student_class_id: newData.student_class_id,
    };

    this.lessons.loading = true;
    const response = await DataService.editData(data, "lesson", lessonId);
    this.lessons.loading = false;
    if (response?.error) {
      return response;
    }

    const newDataMobx = {
      _id: response._id,
      school_plan_config_id: this.schoolPlanId,
      user_id: AuthStore.user.id,
      ...response,
      ...toJS(newData),
    };

    const index = this.lessons.data.findIndex(
      (lesson) => lesson._id === lessonId
    );
    this.editLessonsState(newDataMobx, index);
    return response;
  }

  @action
  async updateTeacher(lessonId, selectedTeacher) {
    const foundLesson = this.lessons.data.find(
      (lesson) => lesson._id === lessonId
    );

    const newData = {
      ...toJS(foundLesson),
      teacher_id: selectedTeacher,
      teacher: selectedTeacher,
    };

    const data = {
      lesson_type_id: newData.$oid,
      size: newData.size,
      amountPerWeek: newData.amountPerWeek,
      teacher_id: selectedTeacher._id,
      lesson_label_id: newData.lesson_label_id,
      allowedClassrooms: newData.allowedClassrooms,
      groups: newData.groups,
      student_class_id: newData.student_class_id,
    };

    this.lessons.loading = true;
    const response = await DataService.editData(data, "lesson", lessonId);
    this.lessons.loading = false;
    if (response?.error) {
      return response;
    }

    const newDataMobx = {
      _id: response._id,
      school_plan_config_id: this.schoolPlanId,
      user_id: AuthStore.user.id,
      ...response,
      ...toJS(newData),
    };

    const index = this.lessons.data.findIndex(
      (lesson) => lesson._id === lessonId
    );

    this.editLessonsState(newDataMobx, index);
    return response;
  }

  @action
  async updateLabel(lessonId, selectedLabel) {
    const foundLesson = this.lessons.data.find(
      (lesson) => lesson._id === lessonId
    );

    const newData = {
      ...toJS(foundLesson),
      lesson_label_id: selectedLabel._id,
      lessonLabel: selectedLabel,
    };

    const data = {
      lesson_type_id: newData.lesson_type_id,
      size: newData.size,
      amountPerWeek: newData.amountPerWeek,
      teacher_id: newData.teacher_id,
      lesson_label_id: newData.lesson_label_id,
      allowedClassrooms: newData.allowedClassrooms,
      groups: newData.groups,
      student_class_id: newData.student_class_id,
    };

    this.lessons.loading = true;
    const response = await DataService.editData(data, "lesson", lessonId);
    this.lessons.loading = false;
    if (response?.error) {
      return response;
    }

    const newDataMobx = {
      _id: response._id,
      school_plan_config_id: this.schoolPlanId,
      user_id: AuthStore.user.id,
      ...response,
      ...toJS(newData),
    };

    const index = this.lessons.data.findIndex(
      (lesson) => lesson._id === lessonId
    );
    this.editLessonsState(newDataMobx, index);

    return response;
  }

  async copyClassrooms(fromLessonId, toLessonId) {
    const fromLesson = this.lessons.data.find(
      (lesson) => lesson._id.$oid === fromLessonId.$oid
    );
    const toLesson = this.lessons.data.find(
      (lesson) => lesson._id.$oid === toLessonId.$oid
    );

    this.lessons.loading = true;
    const response = await DataService.copyClassrooms(fromLessonId, toLessonId);
    this.lessons.loading = false;

    if (response?.error) {
      return response;
    }

    const newDataMobx = {
      _id: response._id,
      ...response,
      ...toJS(toLesson),
      allowedClassroomsDetails: fromLesson?.allowedClassroomsDetails ?? [],
    };
    const index = this.lessons.data.findIndex(
      (lesson) => lesson._id === toLessonId
    );

    this.editLessonsState(newDataMobx, index);
  }

  @action
  updateLessonsData(newLessons) {
    this.lessons.data = [...this.lessons.data, ...newLessons];
  }

  async copyLessons(lessonsToCopy, classId) {
    this.lessons.loading = true;

    const response = await DataService.copyLessons(lessonsToCopy, classId);

    const lessonIds = Object.values(response).map((lesson) => lesson);

    const promises = lessonIds.map((lessonId) =>
      DataService.getLessons("lessons", lessonId)
    );
    const responses = await Promise.all(promises);

    const dataToMobx = responses.map((response) => {
      if (response.error) {
        console.error(response.error);
        return null;
      } else {
        return response.data;
      }
    });

    console.log(
      "response",
      response,
      "lessonIds",
      lessonIds,
      "promises",
      promises,
      "responses",
      responses,
      "dataTomobx",
      dataToMobx
    );

    this.lessons.loading = false;
    this.updateLessonsData(lessonIds);
  }

  findLessonsByClassId(classId) {
    const filteredLessons = toJS(this.lessons.data).filter(
      (lesson) => lesson.student_class_id.$oid === classId
    );
    return filteredLessons;
  }

  @action
  async updateSchoolPlanConfig(updatedData) {
    this.setLoading("schoolPlanConfig", true);

    const newData = {
      ...toJS(updatedData),
      school_plan_config_id: this.schoolPlanId,
      user_id: AuthStore.user.id,
    };

    const response = await DataService.editData(
      newData,
      "schoolPlanConfig",
      this.schoolPlanId
    );
    this.setLoading("schoolPlanConfig", false);

    if (response?.error) {
      return response;
    }

    this.setSchoolPlanConfigState([response]);
  }

  @computed
  get formattedStudentCount() {
    return `Total students: ${this.studentCount}`;
  }

  findLesson(lessonId) {
    return this.lessons.data.find((lesson) => lesson._id.$oid === lessonId);
  }

  findGroup(groupId) {
    return this.groups.data.find((group) => group._id.$oid === groupId);
  }

  findClass(classId) {
    return this.classes.data.find((classItem) => classItem._id.$oid === classId);
  }
}

export default new SchoolInfoStore();
