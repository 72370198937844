import { observable, action, makeAutoObservable, toJS } from 'mobx';
import DataService from '../services/DataService';
import SchoolInfoStore from './SchoolInfoStore';
import { ObjectId } from 'bson';
import { bsonToJs } from '../services/bsonToJs';


class RequirementsStore {
  @observable requirements = [];
  @observable loading = false;
  @observable error = null;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  setRequirements(requirements) {
    this.requirements = requirements;
  }

  @action
  addRequirement(requirement) {
    this.requirements.push(requirement);
  }

  @action
  setError(error) {
    this.error = error;
  }

  @action
  setLoading(loading) {
    this.loading = loading;
  }

  @action
  setDeleteRequirement(id) {
    this.requirements = this.requirements.filter(requirement => requirement._id.$oid !== id);
  };

  async getRequirements(schoolPlanId) {
    this.setLoading(true);
    try {
      const response = await DataService.getRequirements(schoolPlanId);
      this.setRequirements(response);
    } catch (error) {
      this.setError(error);
    } finally {
      this.setLoading(false);
    }
  };

  async deleteRequirement(id) {
    const convertedId = bsonToJs(id);
    try {
      this.setLoading(true);
      const response = await DataService.deleteRequirement(convertedId);
      this.setDeleteRequirement(convertedId);
      return response;
    } catch (error) {
      this.setError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async postRequirement(data) {
    try {
      const schoolPlanId = toJS(SchoolInfoStore.getSchoolPlanIdForRequirements());
      const id = new ObjectId();
      const dataToPost = {
        ...data,
        _id: {$oid: id.toString()},
        school_plan_config_id: schoolPlanId,
      };
      this.setLoading(true);
      // TODO - w response musza byc zwracane wszystkie szczegoly
      const response = await DataService.postRequirement(dataToPost);
      this.addRequirement(response);
      window.location.reload();
      return response;
    } catch (error) {
      this.setError(error);
    } finally {
      this.setLoading(false);
    }
  }
}

export default new RequirementsStore();