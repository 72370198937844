import React, { useState } from "react";
import SchoolInfoStore from "../../../../../mobx/SchoolInfoStore";
import {
  Stack,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Checkbox,
  Chip,
  Button,
} from "@mui/material";
import {
  edgeTypes,
  formListConfig,
  blockingLessonsTypeList,
} from "../../requirementsConfig";
import { toJS } from "mobx";
import RequirementsStore from "../../../../../mobx/RequirementsStore";
import BasicSelect from "../../../../../modules/Inputs/BasicSelect";
import GroupsMultiSelect from "../../../../../modules/Inputs/GroupsMultiselect";
import LessonsListAccordion from "../BlockingLessonATST/LessonsListAccordion";

const EdgeLessonForm = () => {
  const { subjects, classes, labels, lessons } = toJS(SchoolInfoStore);
  const [selectedBlockingType, setSelectedBlockingType] = useState("");

  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [selectedEdgeType, setSelectedEdgeType] = useState("");
  const handleSelectEdgeType = (e) => {
    setSelectedEdgeType(e.target.value);
  };

  const [selectedGroups, setSelectedGroups] = useState([]);
  const handleGroupsChange = (e) => {
    const {
      target: { value },
    } = e;
    setSelectedGroups(typeof value === "string" ? value.split(",") : value);
  };

  const handleItemsChange = (e) => {
    const {
      target: { value },
    } = e;
    setSelectedItems(typeof value === "string" ? value.split(",") : value);
  };

  const handleLessonChange = (lesson) => {
    if (selectedItems.some((l) => l._id.$oid === lesson._id.$oid)) {
      setSelectedItems(selectedItems.filter((l) => l._id.$oid !== lesson._id.$oid));
      return;
    }
    setSelectedItems([...selectedItems, lesson]);
  };

  const handleItemsSelect = (e) => {
    setItems([]);
    setSelectedItems([]);
    setSelectedGroups([]);
    const item = e.target.value;
    setSelectedBlockingType(item);

    switch (item) {
      case "LESSON_TYPE":
        setItems(subjects?.data);
        break;
      case "LESSON_LABEL":
        setItems(labels?.data);
        break;
      case "UNIQUE_ID":
        setItems(lessons?.data);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async () => {
    const data = {
      type: "EDGE_LESSON",
      attType: selectedBlockingType,
      edgeType: selectedEdgeType,
      groupsIds: selectedGroups.map((g) => ({ $oid: g })),
      ids: selectedBlockingType === "LESSON_ID" ? selectedItems.map((l) => l._id) : selectedItems.map((l) => ({ $oid: l })),
    };
    
    const rsp = await RequirementsStore.postRequirement(data);

  };

  return (
    <Stack gap={2}>
      <Stack gap={2}>
        <Stack>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="edgeTypeLabel">Typ krańców</InputLabel>
            <BasicSelect 
              id="edgeType"
              values={edgeTypes}
              label="Typ krańców"
              cb={handleSelectEdgeType}
            />
          </FormControl>
        </Stack>
        {selectedEdgeType.length > 0 && (
          <Stack gap={1}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="blockingTypeLabel">Typ zajęć</InputLabel>
              <BasicSelect
                id="blockingType"
                values={blockingLessonsTypeList}
                label="Typ zajęć"
                cb={handleItemsSelect}
              />
            </FormControl>
          </Stack>
        )}
        {selectedBlockingType.length > 0 && (
            <Stack gap={1}>
              <FormControl>
                <InputLabel id="chooseGroupsLabel">Wybierz grupy</InputLabel>
                <GroupsMultiSelect
                  id="chooseGroups"
                  labelId="chooseGroupsLabel"
                  values={selectedGroups}
                  initialValues={classes.data}
                  label="Wybierz grupy"
                  cb={handleGroupsChange}
                />
             
              </FormControl>
              {selectedGroups.length > 0 && (
                  <Stack>
                    <Stack
                      gap={1}
                      display={"flex"}
                      flexDirection={"row"}
                      flexWrap={"wrap"}
                    >
                      {selectedGroups.map((id) => {
                        const group = classes.data
                          .flatMap((cl) => cl.groups)
                          .find((g) => g._id.$oid === id);
                        const className = classes.data.find((cl) =>
                          cl.groups.some((g) => g._id.$oid === id)
                        )?.name;
                        return (
                          <Chip
                            key={`selected-group-${id}`}
                            label={
                              group && className
                                ? `${className} - ${group.name}`
                                : ""
                            }
                            onDelete={() => {
                              setSelectedGroups((prev) =>
                                prev.filter((groupId) => groupId !== id)
                              );
                            }}
                          />
                        );
                      })}
                    </Stack>
                  </Stack>
                )}
            </Stack>
          )}
        {selectedGroups.length > 0 && selectedBlockingType !== "UNIQUE_ID" && (
          <Stack mt={2}>
            <Stack>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="chooseSubjectsLabel">Wybierz opcje</InputLabel>
                <Select
                  labelId="chooseSubjectsLabel"
                  id="chooseSubjects"
                  multiple
                  value={selectedItems}
                  onChange={handleItemsChange}
                  label="Wybierz opcje"
                  MenuProps={formListConfig.MENU_PROPS}
                  renderValue={(selected) =>
                    selected.map((id) => {
                      // TODO - renderowanie nazwy klasy dodatkowo dla lekcji
                        const option = items.find((s) => s._id.$oid === id);
                        return !option ? "" : selectedBlockingType === "UNIQUE_ID" ? option.lessonType.name : option.name;
                      })
                      .join(", ")
                  }
                >
                  {items.map((subject) => {
                    
                    return (
                      <MenuItem
                      key={`subject-${subject._id.$oid}`}
                      value={subject._id.$oid}
                    >
                      <Checkbox
                        checked={selectedItems.indexOf(subject._id.$oid) > -1}
                      />
                      {selectedBlockingType === "UNIQUE_ID" ? subject.lessonType.name : subject.name}
                    </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              <Stack mt={1} mb={2}>
                <Stack
                  gap={1}
                  display={"flex"}
                  flexDirection={"row"}
                  flexWrap={"wrap"}
                >
                  {selectedItems.map((id) => {
                    const option = items.find((s) => s._id.$oid === id);
                    return (
                      <Chip
                        key={`selected-subject-${id}`}
                        label={!option ? "" : selectedBlockingType === "UNIQUE_ID" ? option.lessonType.name : option.name}
                        onDelete={() => {
                          setSelectedItems((prev) =>
                            prev.filter((subjectId) => subjectId !== id)
                          );
                        }}
                      />
                    );
                  })}
                </Stack>
              </Stack>
              {selectedItems.length ? <Button variant="contained" onClick={handleSubmit}>Dodaj zajęcia na krawędziach</Button> : null}
              </Stack>
          </Stack>
        )}
        {selectedGroups.length > 0 && selectedBlockingType === "UNIQUE_ID" && (
          <Stack gap={1}>
            <LessonsListAccordion 
              classesData={classes.data}
              choosenPreferenceType={items}
              handleLessonChange={handleLessonChange}
              selectedLessons={selectedItems}

            />
              {selectedItems.length > 0 && (
                <Stack mt={2}>
                  <Stack
                    gap={1}
                    display={"flex"}
                    flexDirection={"row"}
                    flexWrap={"wrap"}
                    mb={2}
                  >
                    {selectedItems.map((lesson) => (
                      <Chip
                        key={`selected-lesson-${lesson._id.$oid}`}
                        label={`${lesson.lessonType.name} - ${lesson.className}`}
                        onDelete={() => {
                          setSelectedItems((prev) =>
                            prev.filter((l) => l._id.$oid !== lesson._id.$oid)
                          );
                        }}
                      />
                    ))}
                  </Stack>
                {selectedItems.length ? <Button variant="contained" onClick={handleSubmit}>Dodaj zajęcia na krawędziach</Button> : null}
                </Stack>
              )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default EdgeLessonForm;
