import React, { useState } from "react";
import {
  Stack,
  Typography,
  FormControl,
  InputLabel,
  Chip,
  Button,
} from "@mui/material";
import CustomAlert from "../../../../Alert/CustomAlert";
import { preferencesTypeList } from "../../requirementsConfig";
import SchoolInfoStore from "../../../../../mobx/SchoolInfoStore";
import { toJS } from "mobx";
import SlotsNet from "./SlotsNet";
import RequirementsStore from "../../../../../mobx/RequirementsStore";

import BasicSelect from "../../../../../modules/Inputs/BasicSelect";
import BasicMultiselect from "../../../../../modules/Inputs/BasicMultiselect";
import GroupsMultiSelect from "../../../../../modules/Inputs/GroupsMultiselect";

const PreferencesForm = () => {
  const [formPreferenceType, setFormPreferenceType] = useState("");
  const [selectedPreferenceItems, setSelectedPreferenceItems] = useState(null);
  const [choosenPreferenceUnits, setChoosenPreferenceUnits] = useState([]);
  const [choosenTiles, setChoosenTiles] = useState([]);
  const [error, setError] = useState({isError: false, message: ""});

  const { numOfDays: days, numOfSlotsPerDay: slots } = toJS(
    SchoolInfoStore?.schoolPlanConfig?.data?.[0]
  );

  const handleOptionsChange = (e) => {
    const {
      target: { value },
    } = e;
    setChoosenPreferenceUnits(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleTileChange = (tile) => {
    if (choosenTiles.includes(tile)) {
      setChoosenTiles(choosenTiles.filter((t) => t !== tile));
      return;
    }
    setChoosenTiles([...choosenTiles, tile]);
  };

  const handleFormTemplateChange = (e) => {
    setSelectedPreferenceItems(null);
    setChoosenPreferenceUnits([]);
    const preferenceType = e.target.value;
    setFormPreferenceType(preferenceType);
    switch (preferenceType) {
      case "TEACHER":
        const { teachers } = toJS(SchoolInfoStore);
        setSelectedPreferenceItems(teachers.data);
        break;
      case "CLASSROOM":
        const { classRooms } = toJS(SchoolInfoStore);
        setSelectedPreferenceItems(classRooms.data);
        break;
      case "GROUP":
        const { classes } = toJS(SchoolInfoStore);
        setSelectedPreferenceItems(classes.data);
        break;
      default:
        break;
    }
  };

  const onSubmit = async () => {
    setError({isError: false, message: ""});

    if (choosenTiles.length === 0) {
      setError({isError: true, message: "Wybierz przynajmniej jedną preferowaną godzinę oraz dzień"});
      return;
    }

    const data = {
      type: "PREFERENCE",
      objType: formPreferenceType,
      ids: choosenPreferenceUnits.map((id) => ({ $oid: id })),
      slots: choosenTiles,
    };

    const rsp = await RequirementsStore.postRequirement(data);
    console.log(rsp);
  };

  return (
    <Stack gap={2}>
      {error.isError && <CustomAlert status="error" message={error.message}/>}
      <FormControl fullWidth>
        <InputLabel id="chooseTypeLabel">Wybierz typ preferencji</InputLabel>
        <BasicSelect
          id="chooseType"
          cb={handleFormTemplateChange}
          label="Wybierz typ preferencji"
          values={preferencesTypeList}
        />
      </FormControl>
      {selectedPreferenceItems && (
        <FormControl fullWidth>
          <InputLabel id="choosePreferenceLabel">
            Wybierz opcje z listy
          </InputLabel>
          {formPreferenceType !== "GROUP" ? (
            <BasicMultiselect 
              id="choosePreference"
              values={selectedPreferenceItems}
              selectedValues={choosenPreferenceUnits}
              label="Wybierz opcje z listy"
              cb={handleOptionsChange}
            />
          ) : (
            <GroupsMultiSelect
              id="choosePreference"
              labelId="choosePreferenceLabel"
              label="Wybierz opcje z listy"
              values={choosenPreferenceUnits}
              cb={handleOptionsChange}
              initialValues={selectedPreferenceItems}
            />
          )}
        </FormControl>
      )}
      {choosenPreferenceUnits.length > 0 && (
        <Stack>
          <Typography variant="h6">Wybrane opcje:</Typography>
          {formPreferenceType !== "GROUP" ? (
            <Stack>
              <Stack direction="row" flexWrap="wrap" gap={1} mt={2} mb={2}>
                {choosenPreferenceUnits.map((id) => {
                  const preference = selectedPreferenceItems.find(
                    (p) => p._id.$oid === id
                  );
                  return (
                    <Chip
                      key={`preference-chip-${id}`}
                      label={preference?.name}
                      color="success"
                      onDelete={() =>
                        setChoosenPreferenceUnits(
                          choosenPreferenceUnits.filter((p) => p !== id)
                        )
                      }
                    />
                  );
                })}
              </Stack>
            </Stack>
          ) : (
            <Stack direction="row" flexWrap="wrap" gap={1} mt={2} mb={2}>
              {choosenPreferenceUnits.map((id) => {
                const preference = selectedPreferenceItems.flatMap((cl) => cl.groups).find((g) => g._id.$oid === id);
                const className = selectedPreferenceItems.find((cl) => cl.groups.some((g) => g._id.$oid === id))?.name;
                return (
                  <Chip
                    key={`preference-chip-${id}`}
                    label={preference && className? `${className} - ${preference.name}` : ""}
                    color="success"
                    onDelete={() => setChoosenPreferenceUnits( choosenPreferenceUnits.filter((p) => p !== id))
                    }
                  />
                );
              })}
            </Stack>
          )}

          <SlotsNet
            days={days}
            slots={slots}
            handleTileChange={handleTileChange}
            tiles={choosenTiles}
          />
           <Button variant="contained" onClick={onSubmit}>
            Dodaj preferencje
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default PreferencesForm;
