import React, { useEffect, useState } from "react";
import SchoolInfoStore from "../../../mobx/SchoolInfoStore";
import RequirementsStore from "../../../mobx/RequirementsStore";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { Box, Button, Stack} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import Requirement from "./Requirement/Requirement";
import LoadingBar from "../../LoadingBar/LoadingBar";
import ModalComponent from "../../Modal/ModalComponent";
import FormTemplate from "./RequirementForms/FormTemplate";

const Requirements = observer(() => {
  const { schoolPlanId } = toJS(SchoolInfoStore);
  const { requirements, loading } = toJS(RequirementsStore);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const handleOpen = () => setIsAddFormOpen(true);
  const handleClose = () => setIsAddFormOpen(false);

  useEffect(() => {
    const fetchData = async () => {
      await RequirementsStore.getRequirements(schoolPlanId);
    }
    
    fetchData();
  }, []);

  return (
    <Box width={"90%"}>
      <h2>Wymagania</h2>
      {loading && !requirements ? <LoadingBar /> : 
      <>
        <Button startIcon={<AddIcon/>} variant="contained" onClick={handleOpen}>Dodaj wymaganie</Button>
        <Box mt={2}>
          <Stack gap={3}>
          {requirements ? requirements?.map((requirement) => (
              <Requirement key={requirement._id.$oid} requirement={requirement} />
            )) : "Brak wymagań"}
          </Stack>
        </Box>
        {isAddFormOpen && <ModalComponent handleClose={handleClose} isOpen={isAddFormOpen}><FormTemplate/></ModalComponent>}
      </>}
      
    </Box>
  );
});

export default Requirements;
