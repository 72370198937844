import React, { useState } from "react";
import SchoolInfoStore from "../../../../../mobx/SchoolInfoStore";
import { toJS } from "mobx";
import {
  Stack,
  Typography,
  OutlinedInput,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Chip,
  Button,
  ListSubheader,
  Alert
} from "@mui/material";
import { distributionTypes } from "../../requirementsConfig";
import { formListConfig } from "../../requirementsConfig";
import LDTypeForm from "./LDTypeForm";
import RequirementsStore from "../../../../../mobx/RequirementsStore";

const LessonDistributionForm = () => {
  const { classes } = toJS(SchoolInfoStore);
  const [distributionType, setDistributionType] = useState("");
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [wasEdited, setWasEdited] = useState(false);
  const [error, setError] = useState(false);

  const handleDistributionTypeChange = (e) => {
    setWasEdited(false);
    setDistributionType(e.target.value);
  };

  const handleSubmit = async () => {
    setError(false);
    if(selectedGroups.length === 0) {
      setError(true);
      return;
    }
    console.log(selectedDays)

    const data = {}

    switch (distributionType) {
      case "EVEN":
        data.type="LESSON_DISTRIBUTION";
        data.distType="EVEN";
        data.groupsIds=selectedGroups.map(id => ({$oid: id}));
        break;
      case "SPECIFIC":
        data.type="LESSON_DISTRIBUTION";
        data.distType="SPECIFIC";
        data.groupsIds=selectedGroups.map(id => ({$oid: id}));
        data.distribution=selectedDays;
        break;
      case "RANGE":
        data.type="LESSON_DISTRIBUTION";
        data.distType="RANGE";
        data.groupsIds=selectedGroups.map(id => ({$oid: id}));
        data.range=selectedDays;
        break;
      default:
        break;
    }

    const rsp = await RequirementsStore.postRequirement(data);
    console.log(rsp)
  };

  const setEditChanges = (flag) => {
    setWasEdited(flag);
  };

  const handleChanges = (data) => {
    const preparedData = Object.entries(data).map(([key, value]) => {
      return value
    });
    setWasEdited(true);
    setSelectedDays(preparedData);
  }

  const handleGroupsChange = (e) => {
    setError(false);
    const {
      target: { value },
    } = e;
    setSelectedGroups(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <Stack gap={2}>
      <Stack gap={1}>
        <FormControl fullWidth>
          <InputLabel id="chooseDistributionTypeLabel">
            Wybierz typ dystrybucji
          </InputLabel>
          <Select
            labelId="chooseDistributionTypeLabel"
            id="chooseDistributionType"
            value={distributionType}
            onChange={handleDistributionTypeChange}
            label="Wybierz typ dystrybucji"
          >
            {distributionTypes.map((type) => (
              <MenuItem key={`type-${type.id}`} value={type.type}>
                {type.polishtype}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {distributionType.length > 0 && (
          <Stack gap={1}>
            {error && <Alert severity="error">Wybierz przynajmniej jedną grupę</Alert>}
            <FormControl fullWidth>
              <InputLabel id="chooseGroupsLabel">Wybierz grupy</InputLabel>
              <Select
                labelId="chooseGroupsLabel"
                id="chooseGroups"
                multiple
                value={selectedGroups}
                input={<OutlinedInput label="Wybierz grupy" />}
                onChange={handleGroupsChange}
                error={error}
                label="Wybierz grupy"
                MenuProps={formListConfig.MENU_PROPS}
                renderValue={(selected) =>
                  selected
                    .map((id) => {
                      const group = classes.data
                        .flatMap((cl) => cl.groups)
                        .find((g) => g._id.$oid === id);
                      const className = classes.data.find((cl) =>
                        cl.groups.some((g) => g._id.$oid === id)
                      )?.name;
                      return group && className
                        ? `${className} - ${group.name}`
                        : "";
                    })
                    .join(", ")
                }
              >
                {classes.data.map((cl) => [
                  <ListSubheader key={`class-${cl._id.$oid}`}>
                    {cl.name}
                  </ListSubheader>,
                  ...cl.groups.map((group) => (
                    <MenuItem
                      key={`group-${group._id.$oid}`}
                      value={group._id.$oid}
                    >
                      <Checkbox
                        checked={selectedGroups.indexOf(group._id.$oid) > -1}
                      />
                      {group.name}
                    </MenuItem>
                  )),
                ])}
              </Select>
            </FormControl>
            {selectedGroups.length > 0 && (
              <Stack direction="row" flexWrap="wrap" gap={1}>
                {selectedGroups.map((id) => {
                  const group = classes.data
                    .flatMap((cl) => cl.groups)
                    .find((g) => g._id.$oid === id);
                  const className = classes.data.find((cl) =>
                    cl.groups.some((g) => g._id.$oid === id)
                  )?.name;
                  return (
                    <Chip
                      key={`group-${group._id.$oid}`}
                      label={`${className} - ${group?.name}`}
                      onDelete={() => {
                        setSelectedGroups((groups) =>
                          groups.filter((group) => group !== id)
                        );
                      }}
                    />
                  );
                })}
              </Stack>
            )}
            {selectedGroups.length ? <LDTypeForm type={distributionType} handleChanges={handleChanges} setEditChanges={setEditChanges}/> : null}
            {selectedGroups.length ? <Button variant='contained' onClick={(wasEdited || distributionType === "EVEN") ? handleSubmit : null} disabled={!wasEdited && distributionType !== "EVEN"}>Zapisz</Button> : null}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default LessonDistributionForm;
