import { MenuItem, Select } from "@mui/material";
import React, {useState} from 'react';
import { formListConfig } from "../../components/Profile/Requirements/requirementsConfig";

const BasicSelect = ({id, values, label, cb}) => {
  const [selectedVal, setSelectedVal] = useState('');
  const handleChange = (e) => {
    setSelectedVal(e.target.value);
    cb(e);
  }

  return (
    <Select
      id={id}
      labelId={`${id}Label`}
      label={label}
      onChange={handleChange}
      MenuProps={formListConfig.MENU_PROPS}
      value={selectedVal}
      >
        {values.map((val) => {
          return <MenuItem key={val.type} value={val.type}>{val.polishtype}</MenuItem>
        })}
    </Select>
  );
};

export default BasicSelect;